import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { config } from 'src/environments/config';
import {
  AreaLookupRequest,
  AreaLookupResponse,
  AutoCompleteRequest,
  AutoCompleteResponse,
  BulkCreateRiderInvitationRequest,
  BulkCreateRiderInvitationResponse,
  CreateBusinessRequest,
  CreateBusinessResponse,
  CreateIntercomUserHashRequest,
  CreateIntercomUserHashResponse,
  CreateBookingRequest,
  CreateBookingResponse,
  CreateRiderInvitationRequest,
  CreateRiderInvitationResponse,
  DeleteRiderBusinessProfileRequest,
  DeleteRiderInvitationRequest,
  QuoteRequest,
  QuoteResponse,
  ResendRiderInvitationRequest,
  SendEmailVerificationRequest,
  SendPasswordResetEmailRequest,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
  CancelBookingRequest,
  UpdateBookingRequest,
  LookupNearbyDriversRequest,
  LookupNearbyDriversResponse,
  LogEntriesRequest,
  CallDriverRequest,
} from '../models/b2b-web-api.model';
import { Observable } from 'rxjs';
import { PlaceDetailsRequest, PlaceDetailsResponse } from '../models/google-maps.model';
import {
  CreateCard3DS2Request,
  CreateCard3DS2Response,
  CreateCardRequest,
  CreateCardResponse,
  DeleteCardRequest,
} from '../models/payment-card.model';

@Injectable({
  providedIn: 'root',
})
export class B2BWebApiService {
  private http = inject(HttpClient);

  private apiUrl = config.b2bWebApi;

  signIn(request: SignInRequest) {
    return this.http.post<SignInResponse>(`${this.apiUrl}/signin`, request);
  }

  signUp(request: SignUpRequest) {
    return this.http.post<SignUpResponse>(`${this.apiUrl}/signup`, request);
  }

  log(request: LogEntriesRequest) {
    return this.http.post(`${this.apiUrl}/logentries`, request);
  }

  sendEmailverification(request: SendEmailVerificationRequest) {
    return this.http.post(`${this.apiUrl}/sendemailverification`, request);
  }

  sendPasswordResetEmail(request: SendPasswordResetEmailRequest) {
    return this.http.post(`${this.apiUrl}/sendpasswordresetemail`, request);
  }

  createBusiness(request: CreateBusinessRequest) {
    return this.http.post<CreateBusinessResponse>(`${this.apiUrl}/createbusiness`, request);
  }

  createUserIntercomHash(request: CreateIntercomUserHashRequest) {
    return this.http.post<CreateIntercomUserHashResponse>(`${this.apiUrl}/createintercomuserhash`, request);
  }

  createRiderInvitation(request: CreateRiderInvitationRequest) {
    return this.http.post<CreateRiderInvitationResponse>(`${this.apiUrl}/createriderinvitation`, request);
  }

  deleteRiderInvitation(request: DeleteRiderInvitationRequest) {
    return this.http.post(`${this.apiUrl}/deleteriderinvitation`, request);
  }

  deleteRiderBusinessProfile(request: DeleteRiderBusinessProfileRequest) {
    return this.http.post(`${this.apiUrl}/deleteriderbusinessprofile`, request);
  }

  resendRiderInvitation(request: ResendRiderInvitationRequest) {
    return this.http.post(`${this.apiUrl}/resendriderinvitation`, request);
  }

  bulkCreateRiderInvitation(request: BulkCreateRiderInvitationRequest) {
    return this.http.post<BulkCreateRiderInvitationResponse>(`${this.apiUrl}/bulkcreateriderinvitation`, request);
  }

  placeAutocomplete(request: AutoCompleteRequest): Observable<AutoCompleteResponse> {
    return this.http.post<AutoCompleteResponse>(`${this.apiUrl}/placeautocomplete`, request);
  }

  placeDetails(request: PlaceDetailsRequest): Observable<PlaceDetailsResponse> {
    return this.http.post<PlaceDetailsResponse>(`${this.apiUrl}/placedetails`, request);
  }

  areaLookup(request: AreaLookupRequest): Observable<AreaLookupResponse> {
    return this.http.post<AreaLookupResponse>(`${this.apiUrl}/arealookup`, request);
  }

  quote(request: QuoteRequest): Observable<QuoteResponse> {
    return this.http.post<QuoteResponse>(`${this.apiUrl}/createquote`, request);
  }

  createBooking(request: CreateBookingRequest): Observable<CreateBookingResponse> {
    return this.http.post<CreateBookingResponse>(`${this.apiUrl}/createbooking`, request);
  }

  cancelBooking(request: CancelBookingRequest): Observable<unknown> {
    return this.http.post<unknown>(`${this.apiUrl}/cancelbooking`, request);
  }

  sendTrackingSms(id: string, phoneNumber: string) {
    return this.http.post(`${config.trackingServiceUrl}/tracking-link/send`, {
      bookingId: id,
      phoneNumber,
    });
  }

  updateBooking(request: UpdateBookingRequest) {
    return this.http.post(`${this.apiUrl}/updatebooking`, request);
  }

  lookupNearbyDrivers(request: LookupNearbyDriversRequest) {
    return this.http.post<LookupNearbyDriversResponse>(`${this.apiUrl}/lookupnearbydrivers`, request);
  }

  callDriver(request: CallDriverRequest) {
    return this.http.post(`${this.apiUrl}/calldriver`, request);
  }

  createCard(request: CreateCardRequest) {
    return this.http.post<CreateCardResponse>(`${this.apiUrl}/createcard`, request);
  }

  createCardWith3ds2(request: CreateCard3DS2Request) {
    return this.http.post<CreateCard3DS2Response>(`${this.apiUrl}/submit3dsecureresult`, request);
  }

  deleteCard(request: DeleteCardRequest) {
    return this.http.post(`${this.apiUrl}/deletecard`, request);
  }
}
